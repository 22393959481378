declare global {
  interface Window {
    dataLayer: unknown[]
  }
}
interface TagParams {
  event: string
  variables?: Record<string, unknown>
  leadId?: string
  formStep?: string
}

/** Generic tag function to call dataLayer.push method for GoogleTagManager */
function tag({ event, variables }: TagParams) {
  window.dataLayer.push({
    ...variables,
    event
  });
}

interface FormStepAttributes {
  leadId?: string
  formStep?: string
}
/**
 * Retrieve LeadId and CurrentStep attributes positionally since
 * their are URL params and don't are represented by key.
 */
function getUrlParams(): FormStepAttributes {
  const { pathname } = window.location;
  const splittedPathname = pathname.split('/').filter(Boolean);

  const leadId = splittedPathname.length >= 3 ? splittedPathname.at(-2) : undefined;
  const formStep = splittedPathname.at(-1);

  return {
    formStep,
    leadId
  };
}

// Target DOM elements for attach listeners
const stepForm = <HTMLFormElement>document.querySelector('#step-form');

const formSubmitButton = <HTMLButtonElement>document.querySelector('#submit-button');
const formCancelButton = <HTMLAnchorElement>document.querySelector('#cancel-button');

const successfulRedirectionButtons = [
  <HTMLAnchorElement>document.querySelector('#recommendation-redirection'),
  <HTMLAnchorElement>document.querySelector('#recommendation-transfer-redirection'),
  <HTMLAnchorElement>document.querySelector('#recommendation-help-redirection')
];

const recommendationCancelRedirectionButton = <HTMLAnchorElement>document.querySelector('#recommendation-cancel-redirection');

function tagFormInputs(action: 'cancel' | 'submit') {
  const { formStep, leadId } = getUrlParams();

  const tagVariables: Record<string, unknown> = {
    leadId,
    formStep
  };

  const selectInputs = [...stepForm.querySelectorAll('select')];
  const formInputs = [...stepForm.querySelectorAll('input')].filter((f) => f.type !== 'hidden');

  formInputs.forEach((input) => {
    const { value, name } = input;

    // Variables object registering special cases for checkbox inputs
    if (input.type === 'checkbox') {
      const isChecked = input.checked;
      tagVariables[value] = isChecked;
      return;
    }

    // Variables object registering special cases for radio inputs
    if (input.type === 'radio') {
      const options = <HTMLInputElement[]>[...stepForm.querySelectorAll(`input[name=${name}]`)];
      const selectedOption = options.find((el) => el.checked);

      tagVariables[name] = selectedOption?.value;
      return;
    }

    // Variables object registering for remaining inputs
    tagVariables[name] = value;
  });

  selectInputs.forEach((select) => {
    const { value, name } = select;
    tagVariables[name] = value;
  });

  tag({
    event: action,
    variables: tagVariables
  });
}

function tagRecommendation() {
  const { formStep, leadId } = getUrlParams();

  if (formStep === 'recommendation') {
    const { value } = <HTMLInputElement>document.querySelector('#recommendation-value');

    tag({
      event: 'load-recommendation',
      variables: {
        leadId,
        recommendation: value
      }
    });
  }
}

interface TagRecommendationRedirectionOptions {
  successful: boolean
  event: MouseEvent
}

function tagRecommendationRedirection({ successful, event }: TagRecommendationRedirectionOptions) {
  const { leadId } = getUrlParams();
  const { href } = event.target as HTMLAnchorElement;

  tag({
    event: 'recommendation-redirection',
    variables: {
      leadId,
      successful,
      url: href
    }
  });
}

function loadTagEventListeners() {
  document.addEventListener('DOMContentLoaded', () => tagRecommendation());
  formSubmitButton?.addEventListener('click', () => tagFormInputs('submit'));
  formCancelButton?.addEventListener('click', () => tagFormInputs('cancel'));

  recommendationCancelRedirectionButton?.addEventListener(
    'click',
    (e) => tagRecommendationRedirection({ successful: false, event: e })
  );

  successfulRedirectionButtons.forEach((redirectionLink) => {
    redirectionLink?.addEventListener(
      'click',
      (e) => tagRecommendationRedirection({ successful: true, event: e })
    );
  });
}

loadTagEventListeners();

export {};
