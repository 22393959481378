// Functions
function formatInputValueToMoney(input: HTMLInputElement) {
  const { value } = input;
  const formattedValue = value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  // eslint-disable-next-line no-param-reassign
  input.value = formattedValue;
}

// Initialize behavior
window.addEventListener('DOMContentLoaded', () => {
  const moneyInputs = document.querySelectorAll('[data-type="money"]');

  moneyInputs.forEach((input) => {
    formatInputValueToMoney(input as HTMLInputElement);
    input.addEventListener('input', () => formatInputValueToMoney(input as HTMLInputElement));
  });
});
