export const App = {
  Utils: class Utils {
    static hideLoading() {
      document.body.classList.remove('wait');
    }

    static thousandSeparator(x: number) {
      return Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    static showLoading(element: Element) {
      if (!element.querySelector('.loading-icon')) {
        // eslint-disable-next-line no-param-reassign
        element.innerHTML = '<div class="btn-icon spinner-border loading-icon" role="status"><span class="visually-hidden">Cargando...</span></div>';
      }
    }

    static checkFormIsFulfilled(form: HTMLFormElement) {
      const FIELD_SELECTOR = ':not([type="submit"]):not([type="hidden"])';

      const formData = new FormData(form);
      const fields = [...form.elements].filter((element) => element.matches(FIELD_SELECTOR));
      const fieldsNames = fields.map((field) => field.getAttribute('name') || '');
      const fieldsNamesWithoutDuplicates = [...new Set(fieldsNames)];

      const formHasAnEmptyField = fieldsNamesWithoutDuplicates
        .some((fieldName) => !formData.get(fieldName));

      return !formHasAnEmptyField;
    }

    static toggleDisabledToFormSubmits(form: HTMLFormElement, disable = true) {
      const submits = [...form.elements]
        .filter((element) => element.matches('[type="submit"]')) as (HTMLButtonElement | HTMLInputElement)[];

      submits.forEach((submit) => {
        // eslint-disable-next-line no-param-reassign
        submit.disabled = disable;
      });
    }
  }
};
